import { render } from 'base/wrapped-render';
import { CareersRoot } from "./career-site" 

document.addEventListener('DOMContentLoaded', () => {
	const reactRoot = document.getElementById('js-careers-root');

	if (reactRoot) {
		render(
			<CareersRoot />,
			reactRoot
		);
	}
});
