import { makeStyles } from "@bamboohr/fabric";
import { pageWidths } from '@bamboohr/fabric/dist/definitions/json/layout.json';

const styles = makeStyles(({breakpoints}) => ({
    wrapper: {
        width: "auto",
        maxWidth: '100%',
        padding: `0 ${pageWidths.buffer}px`,
        margin: 'auto',
        marginTop: 0,
        minHeight: '100%',
        boxSizing: 'border-box',
		[breakpoints.down(768)]: {
            width: '755px',
			minWidth: '400px',
			padding: 0,
        },
		[breakpoints.up(992)]: {
            width: '100%',
			minWidth: '700px',
        },
		[breakpoints.up(1200)]: {
            minWidth: pageWidths.min,
            width: pageWidths.max,
        },
    },
}));

export function ResponsiveContentWrapper({ children }: { children: React.ReactNode }): React.ReactElement {
    const classes = styles();

    return (
        <div className={classes.wrapper}>
            {children}
        </div>
    );
}