import { makeStyles } from '@bamboohr/fabric';

export default makeStyles(({ breakpoints }) => {
	return {
		form: {
			'& #careerApplicationForm': {
				[breakpoints.down(1200)]: {
					'& .CandidateField ': {
						wordBreak: 'break-all'
					},
					'& .CandidateField__inputWrapper--firstName': {
						width: '224px' // fab-width 7
					},
					'& .CandidateField__inputWrapper--lastName': {
						width: '224px' // fab-width 7
					},
				},
				[breakpoints.down(992)]: {
					'& .CandidateField__inputWrapper--firstName': {
						width: '288px' // fab-width 8
					},
					'& .CandidateField__inputWrapper--lastName': {
						width: '288px' // fab-width 8
					},
				},
				[breakpoints.down(768)]: {
					'& .CandidateForm__row': {
						flexDirection: 'column',
						alignItems: 'end',
					},
					'& .CandidateField ': {
						width: '100%'
					},
					'& .CandidateField + .CandidateField': {
						marginLeft: 0,
						marginTop: '16px'
					},
					'& .CandidateField__inputWrapper': {
						width: '100%'
					},
					'& .CandidateField__inputWrapper--state': {
						width: '128px'
					},
					'& .CandidateField__multi': {
						width: '100%'
					},
					'& .CandidateField__multiSelect': {
						width: '100%'
					},
					'& .CandidateField__multiOther': {
						width: '100%'
					},
					'& .CandidateField--veteranStatusesShown': {
						width: 'calc(100% - 24px)',
					}
				}
			},
		},
	}
});
