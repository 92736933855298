import { Flex, IconV2, makeStyles, Svg, TextButton } from '@bamboohr/fabric';

const styles = makeStyles(({ breakpoints, palette }) => ({
	dot: {
		[breakpoints.down(768)]: {
			display: 'none',
		},
	},
	footer: {
		display: 'flex',
		alignItems: 'center',
		marginRight: 'auto',
		marginLeft: 'auto',
		maxWidth: '1348px',
		[breakpoints.up(1200)]: {
			flexDirection: 'row',
			height: '128px',
			justifyContent: 'space-between',
			minWidth: '1124px',
			width: '1200px',
		},
		[breakpoints.between(992, 1199)]: {
			flexDirection: 'row',
			height: '128px',
			justifyContent: 'space-between',
			minWidth: '700px',
			width: '100%',
		},
		[breakpoints.between(768, 991)]: {
			flexDirection: 'row',
			height: '128px',
			justifyContent: 'space-between',
			maxWidth: '100%',
			width: 'auto',
		},
		[breakpoints.down(768)]: {
			paddingBottom: '16px',
			paddingTop: '16px',
			height: '120px',
			justifyContent: 'space-evenly',
			flexDirection: 'column-reverse',
			minWidth: '400px',
		},
	},
	text: {
		display: 'flex',
		alignItems: 'center',
		color: palette.gray[700],
		[breakpoints.up(768)]: {
			flexDirection: 'row',
		},
		[breakpoints.down(768)]: {
			flexDirection: 'column',
		},
	},
}));

export const CareerSiteFooter = () => {
	const classes = styles();

	const privacyLink = 'https://www.bamboohr.com/privacy-policy';
	const tosLink = 'https://www.bamboohr.com/terms-of-service';

	const copyright = String.fromCharCode(169);

	return (
		<div className={classes.footer}>
			<div className={classes.text}>
				<Flex alignItems='center'>
					<TextButton href={privacyLink} color='secondary'>
						{$.__('Privacy Policy')}
					</TextButton>
					<span>&nbsp;•&nbsp;</span>
					<TextButton href={tosLink} color='secondary'>
						{$.__('Terms of Service')}
					</TextButton>
				</Flex>
				<span className={classes.dot}>&nbsp;•&nbsp;</span>
				{$.__(`${copyright} BambooHR All rights reserved.`)}
			</div>
			<Svg color='neutral-strong' height={'24px'} image='bamboohr-logo' width={'156px'} />
		</div>
	);
};
