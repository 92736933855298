import { ReactElement } from "react";
import { Card, CardSize, CardContent, CardContentSpacing, Divider, TextField, Flex, makeStyles, Section } from "@bamboohr/fabric";
import {ifFeature} from "@bamboohr/utils/lib/feature";
import { ShareButton } from "base/careers/shared/social/share-button";
import { ShareType } from "base/careers/shared/social/types";
import { FullPosition, CompanyInfo } from "base/careers/shared/types";
import ApplicationStateButtons from "base/careers/shared/components/application-state-buttons";

const styles = makeStyles(({ palette, typography }) => ({
    linkField: {
        marginBottom: ifFeature('encore', '24px', '12px'),
    },
    privacyWrapper: {
        position: "absolute",
        bottom: "18px",
        right: "18px",
    },
    privacyLink: {
        color: palette.gray[500],
        fontSize: typography.teenie.fontSize,
        "&:hover": palette.info.main
    },
    privacyIcon: {
        fill: palette.gray[500],
        marginRight: "10px"
    }
}));

export function ApplyCard({ position, companyInfo }: { position: FullPosition, companyInfo: CompanyInfo }): ReactElement {
	const {
		jobOpeningName,
		jobOpeningShareUrl
	} = position;

    const classes = styles();
    return (
        ifFeature(
            'encore',
            <Section>
                <ApplicationStateButtons fullWidth={true} />
                <Divider light={true} marginY={'24px'} />
                <TextField InputProps={{ readOnly: true }} className={classes.linkField} label={$.__('Link to This Job')} onFocus={(e) => e.target.select()} size={'small'} value={jobOpeningShareUrl} variant={'single'} />
                <Flex justifyContent={'center'}>
                    <ShareButton shareType={ShareType.linkedin} shareText={jobOpeningName} title={companyInfo.name} viewPositionUrl={`${jobOpeningShareUrl}?source=linkedin `}/>
                    <ShareButton shareText={jobOpeningName} shareType={ShareType.twitter} title={companyInfo.name} viewPositionUrl={`${jobOpeningShareUrl}?source=twitter `}/>
                    <ShareButton shareType={ShareType.facebook} title={companyInfo.name} viewPositionUrl={`${jobOpeningShareUrl}?source=facebook `}/>
                </Flex>
            </Section>,
            <Card hasBottomGradient={false} size={CardSize.FULL}>
                <CardContent spacingOverrides={{
                    top: CardContentSpacing.LARGE,
                    bottom: CardContentSpacing.LARGE,
                    left: CardContentSpacing.MEDIUM,
                    right: CardContentSpacing.MEDIUM
                }}>
                    <ApplicationStateButtons fullWidth={true} />
                    <Divider light={true} />
                    <TextField InputProps={{ readOnly: true }} className={classes.linkField} label={$.__('Link to This Job')} onFocus={(e) => e.target.select()} value={jobOpeningShareUrl} />
                    <Flex>
                        <ShareButton shareType={ShareType.linkedin} shareText={jobOpeningName} title={companyInfo.name} viewPositionUrl={`${jobOpeningShareUrl}?source=linkedin `}/>
                        <ShareButton shareText={jobOpeningName} shareType={ShareType.twitter} title={companyInfo.name} viewPositionUrl={`${jobOpeningShareUrl}?source=twitter `}/>
                        <ShareButton shareType={ShareType.facebook} title={companyInfo.name} viewPositionUrl={`${jobOpeningShareUrl}?source=facebook `}/>
                    </Flex>
                </CardContent>
            </Card>
        )
    )
}