import { Card, CardContent, CardContentSpacing, CardSize, Divider, makeStyles, Section, Typography } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { orderBy } from 'lodash';
import { Position } from '../../shared/types';
import React from 'react';
import JobOpeningRow from './job-opening-row';

const styles = makeStyles(({ typography, palette }) => ({
	headerText: {
		color: palette.primary.main,
		marginBottom: '3px',
	},
	headerSubtitle: {
		color: palette.gray[700],
		fontWeight: 'normal',
		marginTop: 0,
		marginBottom: ifFeature('encore', '16px', '34px'),
	},
	departmentLabel: {
		marginBottom: '2px',
		color: palette.primary.main,
		fontSize: typography.small.fontSize,
		fontWeight: typography.fontWeightMedium,
	},
}));

export default function CurrentOpeningsCard({ positions }: { positions: Position[] }): React.ReactElement {
	const classes = styles();
	// Sort jobs by department than concat them to the rest of the positions to maintain correct order
	const sortedDepartments = orderBy(
		Object.values(positions).filter((e) => e.departmentLabel),
		['departmentLabel', 'jobOpeningName'],
		'asc'
	);
	const sortedPositions = orderBy(
		Object.values(positions).filter((e) => !e.departmentLabel),
		'jobOpeningName',
		'asc'
	).concat(sortedDepartments);

	return ifFeature(
		'encore',
		<Section>
			<Typography className={classes.headerText} gutterBottom={false} variant={'h3'}>
				{$.__(`Current Openings`)}
			</Typography>
			<Typography className={classes.headerSubtitle} gutterBottom={false} variant={'small'}>
				{$.__(`Thanks for checking out our job openings. See something that interests you? Apply here.`)}
			</Typography>
			<Divider />
			<ul>
				{sortedPositions.map((position, index) => (
					<div key={position.id}>
						<li>
							{/* @startCleanup encore */}
							{ifFeature(
								'encore',
								null,
								index === 0 || position.departmentId !== sortedPositions[index - 1].departmentId ? (
									<div className={classes.departmentLabel}>{position.departmentLabel}</div>
								) : null
							)}
							{/* @endCleanup encore */}

							<JobOpeningRow position={position} />
						</li>
						{sortedPositions.length - 1 === index ? null : <Divider light={true} />}
					</div>
				))}
			</ul>
		</Section>,
		<Card size={CardSize.FULL}>
			<CardContent
				spacingOverrides={{
					top: CardContentSpacing.SMALL,
					right: CardContentSpacing.BIGGIE,
					bottom: CardContentSpacing.MEDIUM,
					left: CardContentSpacing.BIGGIE,
				}}
			>
				<Typography className={classes.headerText} gutterBottom={false} variant={'h2'}>
					{$.__(`Current Openings`)}
				</Typography>
				<Typography className={classes.headerSubtitle} gutterBottom={false} variant={'h4'}>
					{$.__(`Thanks for checking out our job openings. See something that interests you? Apply here.`)}
				</Typography>
				<Divider />
				<ul>
					{sortedPositions.map((position, index) => (
						<div key={position.id}>
							<li>
								{index === 0 || position.departmentId !== sortedPositions[index - 1].departmentId ? (
									<div className={classes.departmentLabel}>{position.departmentLabel}</div>
								) : null}

								<JobOpeningRow position={position} />
							</li>
							{sortedPositions.length - 1 === index ? null : <Divider light={true} />}
						</div>
					))}
				</ul>
			</CardContent>
		</Card>
	);
}
