import { Flex, IconV2, makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @startCleanup encore */
import { CheckCircle64x64 } from '@bamboohr/grim';
/* @endCleanup encore */
import { ReactElement } from 'react';

const styles = makeStyles(({ palette, typography }) => ({
	successContent: {
		fill: palette.primary.main,
		padding: '38px 0',
	},
	successTitle: {
		fontSize: typography.h3.fontSize,
		color: palette.gray[700],
		marginTop: '32px',
		marginBottom: '8px',
	},
	successSubTitle: {
		fontSize: typography.large.fontSize,
		color: palette.gray[700],
		margin: 0,
	},
}));

export default function SuccessContent(): ReactElement {
	const classes = styles();

	return (
		<div className={classes.successContent}>
			<Flex alignItems='center' flexDirection='column' justifyContent='center'>
				{ifFeature('encore', <IconV2 name='circle-check-regular' size={60} />, <CheckCircle64x64 />)}
				<h3 className={classes.successTitle}>{$.__('Thank You')}</h3>
				<p className={classes.successSubTitle}>{$.__('Your application was submitted successfully')}</p>
			</Flex>
		</div>
	);
}
