import { IconButton, LayoutBox } from '@bamboohr/fabric';
import { Facebook9x16, Linkedin16x15, TrayUp12x12, Twitter16x13 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React from 'react';
import * as Social from 'Social.mod';
import { ShareType } from './types';

interface ShareButtonProps {
	shareType: ShareType;
	companyInTrial?: boolean;
	shareText?: string;
	viewPositionUrl: string;
	title: string;
}

export const ShareButton = ({
	shareType,
	companyInTrial = false,
	viewPositionUrl,
	title,
	shareText = '',
}: ShareButtonProps): React.ReactElement => {
	const shareIcon = () => {
		switch (shareType) {
			case ShareType.facebook:
				return ifFeature('encore', 'facebook-f', <Facebook9x16 />);
			case ShareType.twitter:
				return ifFeature('encore', 'twitter', <Twitter16x13 />);
			case ShareType.linkedin:
				return ifFeature('encore', 'linkedin', <Linkedin16x15 />);
			default:
				return ifFeature('encore', 'up-from-bracket-regular', <TrayUp12x12 />);
		}
	};

	const handleClick = () => {
		switch (shareType) {
			case ShareType.facebook:
				Social.shareFacebook(viewPositionUrl);
				break;
			case ShareType.twitter:
				Social.shareTwitter(viewPositionUrl, { title, shareText });
				break;
			case ShareType.linkedin:
				Social.shareLinkedIn(viewPositionUrl, { shareText });
				break;
			default:
				break;
		}
	};

	return (
		<LayoutBox
			/* @startCleanup encore */
			marginLeft={ifFeature('encore', undefined, '12px')}
			/* @endCleanup encore */
			marginX={ifFeature('encore', '8px')}
		>
			<IconButton
				active={ifFeature('encore', false, true)}
				color={ifFeature('encore', 'primary', 'secondary')}
				disabled={companyInTrial}
				icon={shareIcon()}
				onClick={handleClick}
				rel='noopener noreferrer'
				size='medium'
				variant='outlined'
			/>
		</LayoutBox>
	);
};

ShareButton.defaultProps = {
	companyInTrial: false,
	shareText: '',
};
