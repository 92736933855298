import { Button } from "@bamboohr/fabric"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { CareerAppStatus } from "../types"
import { ApplyButton } from "./apply-button"
import { CareerSiteContext } from "./career-site-provider"
import { ViewJobDescriptionButton } from "./view-job-description-button"

export default function ApplicationStateButtons({ fullWidth } : { fullWidth: boolean }): React.ReactElement {
    const { careerAppStatus, toggleAppVisibility } = useContext(CareerSiteContext)
    const queryParams = new URLSearchParams(location.search)
    const source = queryParams.get('source') || ''
    const queryString = source ? `?source=${source}` : ''

    switch (careerAppStatus) {
        case CareerAppStatus.visible:
        case CareerAppStatus.submitting:
            return (
                <ViewJobDescriptionButton onClick={toggleAppVisibility} />
            )
        case CareerAppStatus.hidden:
            return (
                <ApplyButton fullWidth={fullWidth} onClick={toggleAppVisibility} />
            )
        case CareerAppStatus.submitted:
            return (
                <Link to={`/careers${queryString}`}>
                    <Button color="primary" size="biggie" type="button" width={fullWidth ? 100 : null}>
                        {($.__('See all Job Openings'))}
                    </Button>
                </Link>
            )
        default:
            return null
    }
}