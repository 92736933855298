import { Flex, makeStyles, Theme } from '@bamboohr/fabric';
import { FullPosition } from 'base/careers/shared/types';
import { getLocationDisplay } from 'hiring/utils';
import { ReactElement } from 'react';
import { PositionInfoRow } from './position-info-row';

interface StyleProps {
	horizontal: boolean;
	smallRow: boolean;
}

const styles = makeStyles<Theme, StyleProps>(() => ({
	wrapper: (props) => ({
		paddingTop: props.horizontal || props.smallRow ? '15px' : '23px',
		paddingLeft: props.horizontal || props.smallRow ? 0 : '24px',
		paddingRight: props.horizontal || props.smallRow ? 0 : '24px',
	}),
}));

export function PositionInfoList({
	position,
	horizontal = false,
	divider = true,
	smallRow = false,
}: {
	position: FullPosition;
	horizontal?: boolean;
	divider?: boolean;
	smallRow: boolean;
}): ReactElement {
	const classes = styles({ horizontal, smallRow });

	const locationDisplay = getLocationDisplay(
		position.locationType,
		position.location?.city || position.atsLocation?.city,
		position.location?.state || position.atsLocation?.state,
		position.location?.addressCountry || position.atsLocation?.country,
		null
	);

	return (
		<Flex className={classes.wrapper} flexDirection={horizontal ? 'row' : 'column'} justifyContent='space-between'>
			{locationDisplay && !horizontal && !smallRow ? (
				<PositionInfoRow divider={divider} header={$.__('Location')} smallRow={smallRow} text={locationDisplay} />
			) : null}
			{position.departmentLabel ? (
				<PositionInfoRow divider={divider} header={$.__('Department')} smallRow={smallRow} text={position.departmentLabel} />
			) : null}
			{position.employmentStatusLabel ? (
				<PositionInfoRow
					divider={divider}
					header={$.__('Employment Type')}
					smallRow={smallRow}
					text={position.employmentStatusLabel}
				/>
			) : null}
			{position.minimumExperience ? (
				<PositionInfoRow divider={divider} header={$.__('Minimum Experience')} smallRow={smallRow} text={position.minimumExperience} />
			) : null}
			{position.compensation ? (
				<PositionInfoRow divider={divider} header={$.__('Compensation')} smallRow={smallRow} text={position.compensation} />
			) : null}
		</Flex>
	);
}

PositionInfoList.defaultProps = {
	horizontal: false,
	divider: true,
	smallRow: false,
};
