/* eslint no-unused-expressions: 0 */
import Ajax from '@utils/ajax';
import { CompanyInfo, CareerPageData, Position, FullPosition } from './types';
import {URLSearchParams} from "url";

const SITE_TITLE = document.querySelector('meta[property="og:site_name"]')?.getAttribute('content') || 'BambooHR';
const DEFAULT_TITLE = $.__("Current Openings");
const DEFAULT_DESCRIPTION = $.__('Take a look at the current openings at %1', SITE_TITLE, {note: 'Take a look at the current openings at BambooHR'});

/*
* Get data from the /career/list endpoint
*
* @returns {object: PositionData}			   The data from the /career endpoint
*/
export function requestCareerList (): Promise<Position[]> {
	return Ajax.get(`/careers/list`)
		.then((response) => {
			const { data } = response;
			if (data.result) {
				updateMetaTags();
				return data.result;
			}
			window.setMessage(data.error || window.DEFAULT_ERROR_MESSAGE, 'error');
		}).catch(() => {
			window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
		});
}

/*
* Get data from the /careers/{careerId} endpoint
* @param {number}    id	       The id of the career to get
* @param {string}    source    The query param for the source
*
* @returns {object: PositionData}			   The data from the /career endpoint
*/
export function requestPositionData ({ id }: { id: number }, source: string): Promise<CareerPageData> {
	const queryString = source ? `?source=${source}` : ''
	return Ajax.get(`/careers/${id}/detail${queryString}`)
		.then((response) => {
			const { data } = response;
			if (data.result) {
				updateMetaTags(data.result.jobOpening)
				return data.result;
			}
			window.setMessage(data.error || window.DEFAULT_ERROR_MESSAGE, 'error');
		}).catch(() => {
			window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
		});
}

/*
* Get data from the /careers/company-info endpoint
*
* @returns {object: CompanyInfo}			   The data from the /career endpoint
*/
export function requestCompanyInfo(): Promise<CompanyInfo> {
	return Ajax.get(`/careers/company-info`)
		.then((response) => {
			const { data } = response;
			if (data.result) {
				return data.result;
			}
			window.setMessage(data.error || window.DEFAULT_ERROR_MESSAGE, 'error');
		}).catch(() => {
			window.setMessage(window.DEFAULT_ERROR_MESSAGE, 'error');
		});
}

/*
* Update the MetaTags - Limited SEO value but when hitting the share buttons, social companies, scrap the current pages MetaTags, this ensures they are accurate when navigating between states.
* @param {string}  	jobOpeningShareUrl       The URL for the Job
* @param {string}   jobOpeningName	   		 The Job Opening Name
* @param {string}   description	  			 The Truncated Description
*
* @returns void
*/
function updateMetaTags(data?: FullPosition) {
	const { jobOpeningShareUrl, jobOpeningName, description } = data || {};

	const URL = jobOpeningShareUrl || window.location.href;
	const NAME = jobOpeningName || DEFAULT_TITLE;
	//Abbreviate the description to Facebook standards. See app/BambooHR/Controller/ApplicantTracking/CareersSiteController.php:291
	const PAGE_DESCRIPTION = description?.replace(/<[^>]*>?/gm, '')?.slice(0, 305) || DEFAULT_DESCRIPTION;

	document.querySelector('meta[property="og:url"]')?.setAttribute('content', URL);
	document.querySelector('meta[property="og:title"]')?.setAttribute('content', NAME);
	document.querySelector('meta[property="og:description"]')?.setAttribute('content', PAGE_DESCRIPTION);

	document.querySelector('meta[property="twitter:url"]')?.setAttribute('content', URL);
	document.querySelector('meta[property="twitter:title"]')?.setAttribute('content', NAME);
	document.querySelector('meta[property="twitter:description"]')?.setAttribute('content', PAGE_DESCRIPTION);
}

/*
* Get the employment type that conforms to jobPostingSchema
* @param {string}    employementType	  The employment type coming from Bamboo
*
* @returns {object: JobPostingSchema}
*/
function getEmploymentType(employmentType: string): string {
	switch (employmentType) {
		case 'Full-time': return 'FULL_TIME';
		case 'Part-time': return 'PART_TIME';
		case 'Contractor': return 'CONTRACTOR';
		case 'Furloughed': return 'TEMPORARY';
		case 'Intern': return 'INTERN';
		case 'Volunteer': return 'VOLUNTEER';
		default: return 'OTHER';
	}
}

/*
* Get the job posting schema object from company info
*
* @param {CompanyInfo} companyInfo	  The company info object
* @param {FullPosition} position	  The position object
*
* @returns {object: JobPostingSchema}
*/
export function getJobPostingSchema (company: CompanyInfo, jobPosting: FullPosition, jobOpeningId: string): string {
	return JSON.stringify({
		"@context": "http://schema.org",
		"@type": "JobPosting",
		title: jobPosting.jobOpeningName,
		description: jobPosting.description,
		identifier: {
			"@type": "PropertyValue",
			name: company.name,
			value: jobOpeningId
		},
		datePosted: jobPosting.datePosted,
		employmentType: getEmploymentType(jobPosting.employmentStatusLabel),
		hiringOrganization: {
			"@type": "Organization",
			name: company.name,
			logo: company.logoUrl
		},
		jobLocation: {
			"@type": "Place",
			address: {
				"@type": "PostalAddress",
				addressLocality: jobPosting.location.city,
				addressRegion: jobPosting.location.state,
				postalCode: jobPosting.location.postalCode,
				addressCountry: jobPosting.location.addressCountry
			}
		},
		url: jobPosting.jobOpeningShareUrl
	})
}