import React, { createContext, useEffect } from 'react'
import { CareerAppStatus, CareerSiteContextValue, CompanyInfo } from '../types';
import { requestCompanyInfo } from '../utils';

export const CareerSiteContext = createContext<CareerSiteContextValue | null>(null);

export function CareerSiteProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const [companyInfo, setCompanyInfo] = React.useState<CompanyInfo | null>(null);
    const [careerAppStatus, setCareerAppStatus] = React.useState<CareerAppStatus>(CareerAppStatus.hidden);

    useEffect(() => {
        requestCompanyInfo().then(data => setCompanyInfo(data));

        return () => {
            setCompanyInfo(null);
        }
    }, [])

    const toggleAppVisibility = () => {
        setCareerAppStatus(careerAppStatus === CareerAppStatus.hidden ? CareerAppStatus.visible : CareerAppStatus.hidden);
    }

    const setAppStatus = (status: CareerAppStatus) => {
        setCareerAppStatus(status);
    }

    return (
        <CareerSiteContext.Provider value={{ companyInfo, careerAppStatus, toggleAppVisibility, setAppStatus }}>
            {children}
        </CareerSiteContext.Provider>
    )
}