import { Flex, IconV2, LayoutBox, makeStyles, useMediaQuery } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Computer14x14, Employees16x14, HybridJobs16x16, Location11x14 } from '@bamboohr/grim';
import { getLocationFromObjectForDisplay } from 'hiring/utils';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { JobOpeningLocationTypes, Position } from '../../shared/types';

const styles = makeStyles(({ breakpoints, typography, palette }) => ({
	positionName: {
		fontSize: ifFeature('encore', typography.medium.fontSize, typography.h4.fontSize),
		/* @startCleanup encore */
		fontWeight: ifFeature('encore', '', typography.fontWeightMedium),
		/* @endCleanup encore */
		marginBottom: '8px',
		marginLeft: ifFeature('encore', '16px'),
		[breakpoints.up(992)]: {
			marginBottom: 0,
		},
	},
	icon: {
		fill: palette.gray[600],
		marginTop: '4px',
		marginRight: '7px',
	},
	departmentLabel: {
		color: ifFeature('encore', palette.gray[700]),
		fontSize: typography.teenie.fontSize,
		marginLeft: '16px',
	},
	detailTop: {
		color: ifFeature('encore', palette.gray[900]),
		fontSize: ifFeature('encore', typography.medium.fontSize, typography.small.fontSize),
	},
	detailBottom: {
		fontSize: typography.teenie.fontSize,
		color: palette.gray[700],
	},
	textSection: {
		[breakpoints.up(768)]: {
			width: '220px',
		},
		[breakpoints.down(768)]: {
			width: '40vw',
		},
	},
}));

export default function JobOpeningRow({ position }: { position: Position }): React.ReactElement {
	const classes = styles();
	const mediumAndUp = useMediaQuery('(min-width: 992px)');

	const { bottomText, Icon, EncoreIcon, topText } = useMemo(() => {
		const { locationType, location, atsLocation } = position;
		switch (locationType) {
			case JobOpeningLocationTypes.IN_OFFICE:
				return {
					Icon: Location11x14,
					EncoreIcon: <IconV2 color='neutral-extra-extra-strong' name='location-dot-regular' size={16} />,
					topText: location?.city,
					bottomText: location?.state,
				};
			case JobOpeningLocationTypes.REMOTE:
				return {
					Icon: Computer14x14,
					EncoreIcon: <IconV2 color='neutral-extra-extra-strong' name='desktop-regular' size={16} />,
					topText: $.__('Remote'),
					bottomText: getLocationFromObjectForDisplay(locationType, atsLocation),
				};
			case JobOpeningLocationTypes.HYBRID:
				return {
					Icon: HybridJobs16x16,
					EncoreIcon: <IconV2 color='neutral-extra-extra-strong' name='house-building-regular' size={16} />,
					topText: $.__('Hybrid'),
					bottomText: getLocationFromObjectForDisplay(locationType, location),
				};
			default:
				return {};
		}
	}, [position]);

	const queryParams = new URLSearchParams(location.search);
	const source = queryParams.get('source') || '';
	const queryString = source ? `?source=${source}` : '';

	return ifFeature(
		'encore',
		<Flex
			alignItems={mediumAndUp ? 'center' : 'flex-start'}
			flexDirection={mediumAndUp ? 'row' : 'column'}
			justifyContent='space-between'
			paddingY={'32px'}
			paddingRight={'64px'}
		>
			<Flex flexDirection={'column'} minWidth={'33%'}>
				<Link className={classes.positionName} to={`/careers/${position.id}${queryString}`}>
					{position.jobOpeningName}
				</Link>
				<div className={classes.departmentLabel}>{position.departmentLabel}</div>
			</Flex>
			<Flex minWidth={'67%'} justifyContent={'space-around'} paddingTop={mediumAndUp ? '0px' : '16px'}>
				{position.locationType && (
					<Flex alignItems='flex-start'>
						<LayoutBox marginTop={'4px'} marginRight={'7px'}>
							{EncoreIcon}
						</LayoutBox>
						<div className={classes.textSection}>
							<p className={classes.detailTop}>{topText}</p>
							<p className={classes.detailBottom}>{bottomText}</p>
						</div>
					</Flex>
				)}
				<Flex alignItems='flex-start'>
					<LayoutBox marginTop={'4px'} marginRight={'7px'}>
						<IconV2 color='neutral-extra-extra-strong' name='user-group-regular' size={16} />
					</LayoutBox>
					<div className={classes.textSection}>
						<p className={classes.detailTop}>{position.departmentLabel}</p>
						<p className={classes.detailBottom}>{position.employmentStatusLabel}</p>
					</div>
				</Flex>
			</Flex>
		</Flex>,
		<Flex
			alignItems={mediumAndUp ? 'center' : 'flex-start'}
			flexDirection={mediumAndUp ? 'row' : 'column'}
			justifyContent='space-between'
		>
			<Link className={classes.positionName} to={`/careers/${position.id}${queryString}`}>
				{position.jobOpeningName}
			</Link>
			<Flex>
				{position.locationType && (
					<Flex alignItems='flex-start'>
						<Icon className={classes.icon} />
						<div className={classes.textSection}>
							<p className={classes.detailTop}>{topText}</p>
							<p className={classes.detailBottom}>{bottomText}</p>
						</div>
					</Flex>
				)}
				<Flex alignItems='flex-start'>
					<Employees16x14 className={classes.icon} />
					<div className={classes.textSection}>
						<p className={classes.detailTop}>{position.departmentLabel}</p>
						<p className={classes.detailBottom}>{position.employmentStatusLabel}</p>
					</div>
				</Flex>
			</Flex>
		</Flex>
	);
}
