import {
	Card,
	CardSize,
	CardContent,
	CardContentSpacing,
	Flex,
	makeStyles,
	Button,
	BlankState,
	Section,
	LayoutBox,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @startCleanup encore */
import { Ats107x120 } from '@bamboohr/grim';
/* @endCleanup encore */
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

/* @startCleanup encore */
const styles = makeStyles((theme) => ({
	icon: {
		fill: theme.palette.gray[400],
		marginBottom: '32px',
	},
	header: {
		fontSize: theme.typography.h3.fontSize,
		color: theme.palette.gray[700],
		margin: 0,
	},
	text: {
		fontSize: theme.typography.large?.fontSize ?? 'auto',
		color: theme.palette.gray[700],
		maxWidth: '512px',
		margin: '8px auto 0 auto',
		textAlign: 'center',
	},
	contentWrapper: {
		padding: '72px 0',
	},
	linkButton: {
		marginTop: '24px',
	},
}));
/* @endCleanup encore */

export default function BlankPostingCard({ showLink }: { showLink: boolean }): ReactElement {
	/* @startCleanup encore */
	const classes = styles();
	/* @endCleanup encore */

	return ifFeature(
		'encore',
		<Section>
			<LayoutBox marginY='96px'>
				<BlankState
					icon='bs-clipboard'
					title={$.__('Thank you for your interest. Unfortunately, we are not hiring at this time.')}
					subtitle={$.__('Please check back as we will most certainly be looking for great people to join our team in the future.')}
					actions={
						showLink && [
							<Button color='secondary' href='/careers' variant='contained'>
								{$.__('See Current Openings')}
							</Button>,
						]
					}
				/>
			</LayoutBox>
		</Section>,
		<Card size={CardSize.FULL}>
			<CardContent
				spacingOverrides={{
					top: CardContentSpacing.BIGGIE,
					bottom: CardContentSpacing.BIGGIE,
					left: CardContentSpacing.BIGGIE,
					right: CardContentSpacing.BIGGIE,
				}}
			>
				<Flex alignItems='center' className={classes.contentWrapper} flexDirection='column' justifyContent='center'>
					<Ats107x120 className={classes.icon} />
					<h3 className={classes.header}>{$.__('Thank you for your interest. Unfortunately, we are not hiring at this time.')}</h3>
					<p className={classes.text}>
						{$.__('Please check back as we will most certainly be looking for great people to join our team in the future.')}
					</p>
					{showLink && (
						<Link to='/careers'>
							<Button className={classes.linkButton} variant='outlined'>
								{$.__('See Current Openings')}
							</Button>
						</Link>
					)}
				</Flex>
			</CardContent>
		</Card>
	);
}
