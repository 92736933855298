import { Button, Flex, makeStyles } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @startCleanup encore */
import { DoubleChevronDown6x8 } from '@bamboohr/grim';
/* @endCleanup encore */
import { ReactElement } from 'react';

const styles = makeStyles(({ palette }) => ({
	buttonIcon: {
		marginRight: '4px',
		transform: 'rotate(90deg)',
	},
	button: {
		fill: palette.primary.main,
		'&:hover': {
			fill: palette.primary.light,
		},
	},
}));

export const ViewJobDescriptionButton = ({ onClick }: { onClick: () => void }): ReactElement => {
	const classes = styles();

	return ifFeature(
		'encore',
		<Button color={'secondary'} startIcon={'chevron-left-regular'} onClick={onClick} size={'biggie'} type={'button'} width={100}>
			{$.__('View Job Description')}
		</Button>,
		<Button className={classes.button} color='primary' onClick={onClick} size='biggie' type='button' variant='outlined' width={100}>
			<Flex alignItems='center'>
				<DoubleChevronDown6x8 className={classes.buttonIcon} />
				<span>{$.__('View Job Description')}</span>
			</Flex>
		</Button>
	);
};
